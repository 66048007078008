@import 'shared/sass/index.sass'

.SideDrawer
  position: fixed
  width: 100%
  max-width: 140px
  height: 100%
  font-size: 8px
  text-transform: uppercase
  left: 0
  top: 0
  z-index: 2001
  background-color: $primary-color
  box-sizing: border-box
  transition: transform 0.3s ease-out
  border-radius: $basic-border
  .DrawerHeader
    height: 70px
    display: flex
    div:first-child
      border-right: $basic-border
    div
      width: 73px
      border-bottom: $basic-border
    .CloseButton
      display: flex
      align-items: center
      justify-content: center
      &:hover
        cursor: pointer
        background-color: $primary-color-lighter
  nav
    height: 100%

.Open
  transform: translateX(0)

.Close
  transform: translateX(-100%)

.Logo
  height: 11%
  margin-bottom: 32px