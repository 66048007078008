@import 'shared/sass/index.sass'

.PageTitle
  padding-top: 4em
  margin-left: $col-gap
  margin-bottom: 10px
  @include subtitle-2
  color: $primary-color-lighter

.Playlists
  display: grid
  grid-column-gap: $col-gap
  grid-row-gap: $col-gap
  grid-template-columns: repeat(3, 1fr)
  align-content: start
  flex-wrap: wrap
  margin: 0 $col-gap
  margin-bottom: 3em
  @media screen and (max-width: 1024px)
    width: $col-10
    grid-template-columns: repeat(2, 1fr)
  .Playlist
    display: flex
    flex-direction: row
    height: 130px
    width: 100%
    color: $text-color
    border: $basic-border
    position: relative
    &:hover
      cursor: pointer
      user-select: none
      opacity: 0.7
    .IconEnterBlock
      position: absolute
      right: 0
      top: 0
      border: 1px solid white
      border-radius: 2px
      background-color: $primary-color
      display: flex
      align-items: center
      justify-content: center
      height: 45px
      width: 45px
      z-index: 2
      img
        height: 70%
    .ImageContainer
      height: 130px
      min-width: 130px
      width: 130px
      display: flex
      flex-wrap: wrap
      .Status
        position: absolute
        padding: 5px 20px
        margin: 5px
        background-color: $primary-color-lighter
        text-transform: uppercase
        color: $text-color
      .Image
        height: 100%
        width: 50%
        height: 50%
        overflow: hidden
        display: flex
        align-items: center
        justify-content: center
        border-right: $basic-border
        &:nth-child(1), &:nth-child(2)
          border-bottom: $basic-border
        img
          height: 100%
    .DescriptionContainer
      display: flex
      flex-direction: column
      justify-content: space-between
      padding: 1em
      width: 100%
      height: 100%
      .Title
        width: calc(100% - 45px)
        @include subtitle-1
      .Creator
        @include caption

.Searchbar
  display: flex
  justify-content: space-around
  width: 50vw
  height: 3.5em
  margin: $col-gap
  border: $basic-border
  img
    margin-left: 1em
  input
    @include subtitle-2
    width: 100%
    padding: 0 1em
    margin-left: 1em
    background-color: transparent
    border: 0
