
/* BORDERS */
$basic-border: 1px solid black
@mixin gradient-border
  border: 6px solid
  border-image-slice: 1
  border-image-source: linear-gradient(to bottom right, #FF6565, #6100FF)

/* SPACING */
// $col-quarter: calc(100vw / 96)
$col-gap: 20px
$col-half: calc((100vw - 260px) / 24)
$col-1: calc((100vw - 260px) / 12)
$col-2: calc(calc((100vw - 260px) / 12) * 2 + 20px)
$col-3: calc(calc((100vw - 260px) / 12) * 3 + 40px)
$col-4: calc(calc((100vw - 260px) / 12) * 4 + 60px)
$col-5: calc(calc((100vw - 260px) / 12) * 5 + 80px)
$col-6: calc(calc((100vw - 260px) / 12) * 6 + 100px)
$col-7: calc(calc((100vw - 260px) / 12) * 7 + 120px)
$col-8: calc(calc((100vw - 260px) / 12) * 8 + 140px)
$col-9: calc(calc((100vw - 260px) / 12) * 9 + 160px)
$col-10: calc(calc((100vw - 260px) / 12) * 10 + 180px)
$col-11: calc(calc((100vw - 260px) / 12) * 11 + 200px)
$col-12: calc(calc((100vw - 260px) / 12) * 12 + 220px)
