@import './shared/sass/index.sass'

html
  background-color: $primary-color !important
  color: $text-color !important


/* Some ANTD Modal Tweaks */
button[class="ant-btn ant-btn-primary"]
  background-color: $primary-color !important
  color: $text-color !important
  &:hover
    opacity: 0.7
    cursor: pointer
    user-select: none
div[class="ant-modal-mask"]
  // background-color: $primary-color !important

div[class="ant-progress-inner"]
  background-color: #ff9595 !important

.Homepage
  height: calc(100vh - 60px)
  overflow-y: scroll
  > div
    height: auto
    overflow: hidden

.Container
  .Content
    min-height: calc(100vh - 60px)
    overflow: hidden
    background-color: $primary-color
    color: $text-color
    font-size: 0.8rem
    // @media screen and (max-width: 768px)
    //   font-size: 1.3rem
    // @media screen and (max-width: 512px)
    //   font-size: 1.2rem