@import 'shared/sass/index.sass'

.DemosContainer
  position: relative
  height: calc(100vh - 60px)
  overflow-y: scroll
  .ButtonNewDemo
    position: absolute
    display: flex
    align-items: center
    justify-content: space-evenly
    border: 1px solid white
    top: $col-gap
    right: $col-gap
    height: 3.2em
    width: 12em
    border-radius: 4em
    padding: 0 1em
    @include subtitle-1
    &:hover
      cursor: pointer
      user-select: none
      opacity: 0.7
    .ButtonNewDemoText
      padding: 0.6em
      margin-top: -0.1em
  .PageTitle
    margin-top: 24px
    padding-top: 4em
    margin-left: $col-gap
    margin-bottom: 10px
    @include subtitle-2
    color: $primary-color-lighter
  .Demos
    display: grid
    grid-column-gap: $col-gap
    grid-row-gap: $col-gap
    grid-template-columns: repeat(3, 1fr)
    align-content: start
    flex-wrap: wrap
    margin: 0 $col-gap
    margin-bottom: 3em
    .Demo
      height: 160px
      width: 100%
      padding: 1em
      // background-color: $primary-color-lighter-2
      border: $basic-border
      color: $text-color
      position: relative
      display: flex
      justify-content: space-between
      flex-direction: column
      &:hover
        cursor: pointer
        user-select: none
        opacity: 0.7
      .IconEnterBlock
        position: absolute
        right: 0
        top: 0
        border: 1px solid white
        border-radius: 2px
        background-color: $primary-color
        display: flex
        align-items: center
        justify-content: center
        height: 45px
        width: 45px
        z-index: 2
        img
          height: 70%
      .DemoInfos
        .Title
          @include subtitle-1
          max-width: calc(100% - 4em)
          overflow: hidden
          margin-bottom: 10px
        .KeyInfo
          @include subtitle-2
          margin-bottom: 5px
          display: flex
          align-items: center
      .Visitors
        @include caption
        position: absolute
        bottom: 1em
        left: 1em

.Searchbar
  display: flex
  justify-content: space-around
  width: 50vw
  height: 3.5em
  margin: $col-gap
  margin-bottom: 0
  border: $basic-border
  img
    margin-left: 1em
  input
    @include subtitle-2
    width: 100%
    padding: 0 1em
    margin-left: 1em
    background-color: transparent
    border: 0

.NoDemosToday
  @include subtitle-2