
/* FONTS */
// @mixin headline-1
//   text-transform: uppercase
//   font-size: 2em
//   font-size: 500
//   font-weight: bold
//   letter-spacing: 0.10em
@mixin headline-1
  text-transform: uppercase
  font-size: 30px
  font-weight: 700
  line-height: 30px
  letter-spacing: 6px
@mixin subtitle-1
  text-transform: uppercase
  font-size: 18px
  font-weight: 500
  letter-spacing: 3px
  line-height: 20px
@mixin subtitle-2
  font-size: 14px
  font-weight: 400
  letter-spacing: 2px
  line-height: 15px
  text-transform: uppercase
  font-variant: small-caps
@mixin body-1
  font-size: 14px
  font-weight: 400
  letter-spacing: 3px
  line-height: 15px
@mixin body-2
  font-size: 14px
  font-weight: 400
  letter-spacing: 0px
  line-height: 16px
@mixin caption
  font-weight: 400
  font-size: 10px
  text-transform: uppercase
  letter-spacing: 1.5px
  line-height: 11px
