@import 'shared/sass/index.sass'

.MediaWrapper
  margin: 1vh
  border: $basic-border
.Media
  height: 260px
  width: 100%
  overflow: hidden
  // max-width: 16em
  position: relative
  border: solid 6px transparent
  &.Playing
    @include gradient-border
  .HiddenContent
    position: absolute
    height: 140px
    margin-top: 60px
    width: 100%
    padding: 0.5em
    padding-right: 2em
    overflow: hidden
    .Description
      @include body-2
      width: calc(100% - 2.5em)
      padding: 0.5rem
    .ButtonClose
      position: absolute
      top: 10px
      right: 10px
      border: 1px solid white
      border-radius: 100%
      height: 1.5em
      width: 1.5em
      display: flex
      align-items: center
      justify-content: center
      font-size: 2em
      overflow: hidden
      &:hover
        cursor: pointer
      img
        height: 50%
  .MediaImage
    height: 200px
    background-color: black
    display: flex
    align-items: center
    justify-content: center
    font-weight: bold
    font-size: 2em
    position: relative
    overflow: hidden
    &.MediaInfoClosed
      animation: close-media-info 0.5s ease-out forwards
    &.MediaInfoOpen
      animation: open-media-info 0.5s ease-in forwards
    .ButtonInfo
      position: absolute
      bottom: 0.5em
      right: 0.5em
      padding: 0.5em
      border: 1px solid white
      border-radius: 100%
      height: 1.5em
      width: 1.5em
      display: flex
      align-items: center
      justify-content: center
      z-index: 2
      &:hover
        cursor: pointer
      div
        margin-top: 0.05em
    .Thumbnail
      position: absolute
      height: 100%
      transform: scale(1.2)
    .BackwardButton
      position: absolute
      left: calc(50% - 125px)
      height: 60px
      width: 60px
      border-radius: 3.5em
      display: flex
      align-items: center
      justify-content: center
      background-color: $primary-color
      &:hover
        cursor: pointer
      .BorderCircle
        height: 100%
        position: absolute
      img
        height: 100%
        z-index: 2
    .ForwardButton
      position: absolute
      left: calc(50% + 65px)
      height: 60px
      width: 60px
      border-radius: 3.5em
      display: flex
      align-items: center
      justify-content: center
      background-color: $primary-color
      &:hover
        cursor: pointer
      .BorderCircle
        height: 100%
        position: absolute
      img
        height: 100%
        z-index: 2
    .PauseButton
      position: absolute
      height: 85px
      width: 85px
      padding: 1em
      border-radius: 3.5em
      display: flex
      align-items: center
      justify-content: center
      background-color: $primary-color
      &:hover
        cursor: pointer
      .BorderCircle
        height: 100%
        position: absolute
      img
        height: 100%
        z-index: 2
    .PlayButton
      position: absolute
      height: 3em
      width: 3em
      border: 1px solid white
      padding: 1em
      border-radius: 100%
      display: flex
      align-items: center
      justify-content: center
      background-color: $primary-color
      &:hover
        cursor: pointer
      img
        height: 120%
        margin-right: -0.3em
  .MediaInfos
    height: 60px
    display: flex
    border-top: $basic-border
    background-color: $primary-color
    &.MediaInfoClosed
      animation: close-media-info-title 0.5s ease-out forwards
    &.MediaInfoOpen
      animation: open-media-info-title 0.5s ease-in forwards
    .MediaLogo
      display: flex
      align-items: center
      justify-content: center
      height: 100%
      img
        margin-left: 1em
        margin-right: 1em
        height: 50%
        &.IconMediaImage
          transform: scale(1.2)
    .MediaTitle
      display: flex
      align-items: center
      padding-left: 1vh
      @include subtitle-1
      font-size: 14px
      padding: 10px 0

@keyframes open-media-info
  0%
    transform: translateY(0)
  100%
    transform: translateY(-100%)

@keyframes close-media-info
  0%
    transform: translateY(-100%)
  100%
    transform: translateY(0)

@keyframes open-media-info-title
  0%
    transform: translateY(0)
  100%
    transform: translateY(-200px)
    border-top: 0
    border-bottom: $basic-border

@keyframes close-media-info-title
  0%
    transform: translateY(-200px)
  100%
    transform: translateY(0)