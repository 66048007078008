@import 'shared/sass/index.sass'

.Content
  height: 100vh
  padding-top: 60px
  background-color: $primary-color
  color: $text-color

.pageTitle
  text-align: center
