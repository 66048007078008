@import 'shared/sass/index.sass'

.GallerySection
  border-bottom: $basic-border
  padding-bottom: 1em

.ContainerTitle
  @include caption
  margin: $col-gap
  margin-bottom: 1em
  text-transform: uppercase
  color: $text-color-lighter

.Grid
  display: grid
  grid-column-gap: $col-gap
  grid-row-gap: $col-gap
  grid-template-columns: repeat(2, 1fr)
  align-content: start
  flex-wrap: wrap
  margin: 0 $col-gap
  margin-bottom: 3em
  @media screen and (min-width: 1450px)
    grid-template-columns: repeat(3, 1fr)
