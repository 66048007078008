@import 'shared/sass/index.sass'

.Actions
  position: absolute
  top: 20px
  right: 20px
  display: flex
  flex-direction: column
  align-items: flex-end

.ActionsScrub
  margin-top: 10px
  display: flex
  align-items: center

.BackwardButton
  margin-right: 15px
  position: relative
  height: 70px
  width: 70px
  border-radius: 3.5em
  display: flex
  align-items: center
  justify-content: center
  background-color: $primary-color
  &:hover
    cursor: pointer
  .BorderCircle
    height: 100%
    position: absolute
  img
    height: 100%
    z-index: 2

.ForwardButton
  position: relative
  height: 70px
  width: 70px
  border-radius: 3.5em
  display: flex
  align-items: center
  justify-content: center
  background-color: $primary-color
  &:hover
    cursor: pointer
  .BorderCircle
    height: 100%
    position: absolute
  img
    height: 100%
    z-index: 2

.FullWidth
  width: 100% !important

.ModalTechnology
  border: $basic-border
  div
    background-color: $primary-color
    color: $text-color
    button
      span[class=ant-modal-close-x]
        color: $text-color
    div[class=ant-modal-header]
      background-color: $primary-color
      color: $text-color
      div
        background-color: $primary-color
    .ModalContentTitle
      margin-bottom: 0.5em
      font-size: 1.2em
    .ButtonPlayTechnology
      font-size: 1em
      background-color: $primary-color-lighter
      border-radius: 1em
      padding: 0.5em
      margin-bottom: 1em
      display: inline-block
      &:hover
        opacity: 0.7
        cursor: pointer
        user-select: none

.ProjectContainer
  width: 100%
  position: absolute
  left: 0
  display: flex
  flex-direction: row
  .LeftContent
    width: 75%
    height: calc(100vh - 60px)
    overflow-y: scroll
    display: flex
    flex-direction: column
    position: relative
    @media screen and ( min-width: 1025px)
      width: 80%
    .ContentHeader
      background: $primary-color
      top: 0
      z-index: 100
      height: calc(180px - 1px)
      position: sticky
      display: flex
      border-bottom: $basic-border
      .PreviewImageContainer
        display: flex
        align-items: center
        justify-content: center
        width: 230px
        margin-left: 20px
        overflow: hidden
        @media screen and (max-width: 768px)
          display: none
        .PreviewImage
          border-right: $basic-border
          border-left: $basic-border
          height: 100%
      .StopButton
        position: relative
        display: flex
        align-items: center
        justify-content: center
        height: 58px
        width: 135px
        border-radius: 4em
        font-size: 2em
        &:hover
          cursor: pointer
          user-select: none
          opacity: 0.7
        img
          height: 100%
      .PlayButton
        position: relative
        display: flex
        align-items: center
        justify-content: center
        background-color: $secondary-color
        height: 58px
        width: 135px
        border-radius: 4em
        font-size: 2em
        &:hover
          cursor: pointer
          user-select: none
          opacity: 0.7
        img
          height: 60%
      .HeaderInfos
        display: flex
        flex-direction: column
        width: calc(100% - 400px)
        padding-left: 20px
        padding-top: 20px
        .Title
          @include headline-1
          padding-bottom: 10px
        .Tags
          height: 2em
          @include body-1
        .CreatedContainer
          position: absolute
          bottom: 20px
          display: flex
          flex-direction: row
          @include body-1
          .CreatedInfo
            display: flex
            flex-direction: column
            .CreatedInfoText
              margin-bottom: 5px
            .CreatedInfoDate
    .Content
      width: 100%
      .Tabs
        display: flex
        height: 3em
        width: 100%
        @media screen and (max-width: 768px)
          height: 4em
        @media screen and (max-width: 512px)
          height: 5em
        .Tab:first-child
          border-right: $basic-border
        .Tab
          display: flex
          align-items: center
          justify-content: center
          width: 50%
          border-bottom: $basic-border
          @include caption
          text-transform: uppercase
          color: $text-color-lighter
          &:hover, &.Active
           background-color: $primary-color
           color: $text-color
           border-bottom: 1px solid white
           cursor: pointer
           user-select: none
    .Infos
      display: flex
      width: 100%
      flex-direction: column
      .PresetsContainerWrapper
        min-height: 200px
        border-bottom: $basic-border
        .PresetsContainer
          width: 100%
          padding: $col-gap
          .ContainerTitle
            margin-bottom: 10px
            @include caption
            text-transform: uppercase
            color: $text-color-lighter
          .NotLoadedMessageBlock
            @include subtitle-2
            height: 116px
            width: $col-3
            border: 1px solid $status-notcurated-color
            position: relative
            @media screen and ( min-width: 1025px )
              width: $col-2
            .WarningIcon
              position: absolute
              top: 10px
              right: 10px
            .NotLoadedMessage
              position: absolute
              width: 60%
              bottom: 10px
              left: 10px
          .Presets
            display: grid
            grid-template-columns: repeat(3, 1fr)
            grid-gap: $col-gap
            width: 100%
            .Preset
              width: 100%
              margin-left: 0
              height: 116px
              outline: $basic-border
              display: flex
              justify-content: space-between
              position: relative
              border: 6px solid transparent
              &.Active
                @include gradient-border
                user-select: none
                cursor: pointer
              &:hover
                @include gradient-border
                user-select: none
                cursor: pointer
              .Image
                position: absolute
                top: 1em
                right: 1em
                width: 4.5em
                height: 4.5em
                border: 1px solid white
                padding: 1em
                border-radius: 5em
                display: flex
                align-items: center
                justify-content: center
                // display: none
                img
                  margin-left: 0.3em
                  height: 100%
              .PresetInfos
                display: flex
                justify-content: space-between
                flex-direction: column
                .Title, .Number
                  padding: 15px
                .Number
                  @include subtitle-1
                .Title
                  @include subtitle-2
      .Row2
        min-height: 24em
        display: flex
        .NotesContainer
          width: 66.666%
          border-right: $basic-border
          padding: $col-gap
          .Notes
            padding: 10px
            img
              margin-right: 10px
              margin-top: -3px
          .ContainerTitle
            margin-bottom: 0.5em
            @include caption
            text-transform: uppercase
            color: $text-color-lighter
        .TechnologiesAndSoftwares
          width: 33.333%
          display: flex
          flex-direction: column
          .TechnologiesContainer
            border-bottom: $basic-border
            padding: $col-gap
            .ContainerTitle
              margin-bottom: 10px
              @include caption
              text-transform: uppercase
              color: $text-color-lighter
            .Technologies
              display: grid
              grid-gap: 10px
              grid-template-columns: repeat(2, 1fr)
              @media screen and (min-width: 1025px)
                grid-template-columns: repeat(3, 1fr)
                // min-width: $col-4
                width: 100%
              .Technology
                height: 90px
                width: 100%
                border: $basic-border
                position: relative
                &:hover
                  cursor: pointer
                  opacity: 0.7
                  user-select: none
                .IconTechnology
                  position: absolute
                  left: 1em
                  top: 0.5em
                  background-color: $primary-color
                  display: flex
                  align-items: center
                  justify-content: center
                  height: 30px
                  width: 30px
                  z-index: 2
                .IconEnterBlock
                  position: absolute
                  right: 0
                  top: 0
                  border: 1px solid white
                  border-radius: 2px
                  background-color: $primary-color
                  display: flex
                  align-items: center
                  justify-content: center
                  height: 40px
                  width: 40px
                  z-index: 2
                  img
                    width: 2em
                .Title
                  position: absolute
                  bottom: 0.5em
                  left: 1em
                  @include body-1
                  text-transform: uppercase

      .Row3
        height: 12em
        border-bottom: $basic-border
        padding: 0.5em 1.5em

.RelatedProjects
  display: grid
  grid-column-gap: $col-gap
  grid-row-gap: $col-gap
  grid-template-columns: repeat(2, 1fr)
  align-content: start
  flex-wrap: wrap
  @media screen and (min-width: 1025px)
    grid-template-columns: repeat(3, 1fr)
  @media screen and (min-width: 1600px)
    grid-template-columns: repeat(6, 1fr)
  .Project
    height: 220px
    width: 100%
    color: $text-color
    position: relative
    border: $basic-border
    overflow: hidden
    &:hover
      cursor: pointer
      user-select: none
      opacity: 0.7
    .IconEnterBlock
      position: absolute
      right: 0
      top: 0
      border: 1px solid white
      border-radius: 2px
      background-color: $primary-color
      display: flex
      align-items: center
      justify-content: center
      height: 45px
      width: 45px
      z-index: 2
      img
        height: 70%
    .ImageContainer
      height: 160px
      display: flex
      align-items: center
      justify-content: center
      background-color: black
      position: relative
      overflow: hidden
      .StatusNotCurated
        position: absolute
        padding: 5px 12px
        margin: 5px
        bottom: 0
        right: 0
        background-color: $status-notcurated-color
        text-transform: uppercase
        z-index: 3
        @include subtitle-1
        font-size: 0.8em
        color: black
      .Image
        height: 100%
        background-color: $secondary-color
        transform: scale(1.3)
    .DescriptionContainer
      height: 60px
      display: flex
      align-items: center
      padding: 10px
      overflow: hidden
      .Logo
        margin-right: 10px
        height: 100%
      .Title
        @include subtitle-1

.RightContent
  width: 25%
  height: calc(100vh - 60px)
  overflow-y: scroll
  border-left: $basic-border
  @media screen and ( min-width: 1025px)
    width: 20%

@keyframes open-media-info
  0%
    transform: translateY(0)
  100%
    transform: translateY(-100%)

@keyframes close-media-info
  0%
    transform: translateY(-100%)
  100%
    transform: translateY(0)

@keyframes open-media-info-title
  0%
    transform: translateY(0)
  100%
    transform: translateY(-200px)
    border-bottom: $basic-border

@keyframes close-media-info-title
  0%
    transform: translateY(-200px)
  100%
    transform: translateY(0)
