@import 'shared/sass/index.sass'

.DrawerToggle
  width: 76px
  height: 100%
  border-right: $basic-border
  display: flex
  justify-content: center
  align-items: center
  &:hover
    user-select: none
    cursor: pointer
    svg
      path
        stroke-width: 4
  svg
    path
      stroke-width: 2
      transition: stroke-width 0.3s ease