@import 'shared/sass/index.sass'

.PageTitle
  padding-top: 10px
  @include subtitle-1
  color: $primary-color-lighter
  margin-left: 1.6em

.Medias
  display: grid
  grid-column-gap: $col-gap
  grid-row-gap: $col-gap
  grid-template-columns: repeat(3, 1fr)
  align-content: start
  flex-wrap: wrap
  margin: 0 $col-gap
  margin-bottom: 3em

.Searchbar
  display: flex
  justify-content: space-around
  width: 50vw
  height: 3.5em
  margin: $col-gap
  border: $basic-border
  img
    margin-left: 1em
  input
    @include subtitle-2
    width: 100%
    padding: 0 1em
    margin-left: 1em
    background-color: transparent
    border: 0
