@import 'shared/sass/index.sass'

.VolumeLevelContainer
  user-select: none
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: flex-start
  position: relative
  &:hover
    cursor: pointer
  .VolumeLevel
    height: 100%
    background-color: $primary-color-lighter
    position: absolute
    z-index: -1
  .VolumeIcon
    padding-left: 1em