@import 'shared/sass/index.sass'

.Dropdown
  margin: $col-gap 0
  margin-right: 10px
  width: $col-3
  background-color: transparent
  @include subtitle-2
  border: $basic-border
  color: $text-color-lighter
  display: flex
  align-items: center
  justify-content: space-between
  padding: 10px
  word-break: break-all
  &:hover
    cursor: pointer

.DropdownMenu
  background-color: $primary-color
  border: $basic-border
  padding: 0
  border-radius: 0
  &:hover
    cursor: pointer

.DropdownMenuItem
  color: white
  border-bottom: $basic-border
  height: 100%
  width: 100%
  @include subtitle-2
  height: 30px
  display: flex
  align-items: center
  &:hover
    color: black
