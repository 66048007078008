@import 'shared/sass/index.sass'

.PlaylistContainer
  padding-top: 20px
  position: relative
  padding-bottom: 4em
  background-color: $primary-color
  height: calc(100vh - 60px)
  overflow-y: scroll
  .Details
    display: flex
    flex-direction: row
    margin: 0 2%
    margin-bottom: 2.5em
    @media screen and (max-width: 768px)
      margin-top: 3em
    .ImageContainer
      height: $col-3
      width: $col-3
      display: flex
      flex-wrap: wrap
      .Image
        height: 100%
        width: 50%
        height: 50%
        overflow: hidden
        display: flex
        align-items: center
        justify-content: center
        border-right: $basic-border
        border-bottom: $basic-border
        &:nth-child(1), &:nth-child(2)
          border-top: $basic-border
        &:nth-child(1), &:nth-child(3)
          border-left: $basic-border
        img
          height: 100%
    .KeyInfos
      display: flex
      align-items: left
      padding: 0 1em
      flex-direction: column
      position: relative
      .Title
        @include headline-1
      .Hashtag
      .CreatedContainer
        display: flex
        flex-direction: row
        padding-bottom: 2vh
        .CreatedImage
          background-color: $secondary-color
          height: 3em
          width: 3em
          margin-right: 1em
          border-radius: 3em
        .CreatedInfo
          display: flex
          flex-direction: column
          margin-top: 10px
          @include body-1
          line-height: 20px
          .CreatedInfoText
          .CreatedInfoDate
      .Description
        position: absolute
        bottom: $col-1
        width: 60vw
        max-width: 450px
        @include body-2
    .PlayButton
      position: absolute
      display: flex
      align-items: center
      justify-content: center
      background-color: $secondary-color
      top: 20px
      right: 1em
      height: 62px
      width: 144px
      border-radius: 4em
      font-size: 2em
      &:hover
        cursor: pointer
        user-select: none
        opacity: 0.7
      img
        height: 60%
  .Playlist
    display: grid
    grid-column-gap: $col-gap
    grid-row-gap: $col-gap
    grid-template-columns: repeat(3, 1fr)
    align-content: start
    flex-wrap: wrap
    margin: 0 $col-gap
    margin-bottom: 3em
    @media screen and (min-width: 1025px)
      grid-template-columns: repeat(4, 1fr)
    .PlaylistItem
      height: 220px
      width: 100%
      color: $text-color
      position: relative
      border: $basic-border
      overflow: hidden
      &:hover
        cursor: pointer
        user-select: none
        opacity: 0.7
      .IconEnterBlock
        position: absolute
        right: 0
        top: 0
        border: 1px solid white
        border-radius: 2px
        background-color: $primary-color
        display: flex
        align-items: center
        justify-content: center
        height: 45px
        width: 45px
        z-index: 2
        img
          height: 70%
      .ImageContainer
        height: 160px
        display: flex
        align-items: center
        justify-content: center
        background-color: black
        position: relative
        overflow: hidden
        .StatusNotCurated
          position: absolute
          padding: 5px 12px
          margin: 5px
          bottom: 0
          right: 0
          background-color: $status-notcurated-color
          text-transform: uppercase
          z-index: 3
          @include subtitle-1
          font-size: 0.8em
          color: black
        .Image
          height: 100%
          background-color: $secondary-color
          transform: scale(1.3)
      .DescriptionContainer
        height: 60px
        display: flex
        align-items: center
        padding: 10px
        overflow: hidden
        .Logo
          margin-right: 10px
          height: 100%
        .Title
          @include subtitle-1
