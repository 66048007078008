@import 'shared/sass/index.sass'

.PlaylistHeader
  height: 130px
  position: relative
  border-bottom: $basic-border
  padding: 2em

.PlaylistHeaderTitle
  margin-bottom: 1em
  color: $text-color-lighter
  @include caption

.PlaylistName
  @include subtitle-1

.ButtonStopDemo
  position: absolute
  background-color: $primary-color
  border: 1px solid white
  border-radius: 3em
  padding: 0.5em 1em
  bottom: 1em
  right: 1em
  &:hover
    cursor: pointer
    user-select: none
    opacity: 0.7

.ProtosAndTransitions
  width: 100%
