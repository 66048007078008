
/* COLORS */
$primary-color: #222222
$primary-color-lighter: #666666
$secondary-color: #6100FF
$text-color: white
$text-color-lighter: #646464
$status-notcurated-color: #FF6565

$status-connected-color: #89E1AC
$status-invited-color: #FF6565
$status-checked-in-color: #DACDFF

// $primary-color: #131a68
// $primary-color-lighter: #192285
// $primary-color-lighter-2: #1d28a0
// $secondary-color: #7a83e8
// $text-color: white

// $primary-color: #a5a01a
// $primary-color-lighter: #bfb91f
// $primary-color-lighter-2: #d0c922
// $secondary-color: #f1ea4e
// $text-color: black

// $primary-color: #00f7ff
// $primary-color-lighter: #00dce4
// $primary-color-lighter-2: #00e1ec
// $secondary-color: #9dfaff
// $text-color: black

// $primary-color: #a51a94
// $primary-color-lighter: #ca1fb5
// $primary-color-lighter-2: #e122ca
// $secondary-color: #ff7aef
// $text-color: black
