@import 'shared/sass/index.sass'

.PageTitle
  @include subtitle-2
  color: $primary-color-lighter

.SearchActions
  display: flex
  .Searchbar
    display: flex
    justify-content: space-around
    width: 45vw
    height: 3.5em
    margin: $col-gap
    margin-right: 10px
    border: $basic-border
    img
      margin-left: 1em
    input
      @include subtitle-2
      width: 100%
      padding: 0 1em
      margin-left: 1em
      background-color: transparent
      border: 0

.Protos
  display: grid
  grid-column-gap: $col-gap
  grid-row-gap: $col-gap
  grid-template-columns: repeat(3, 1fr)
  align-content: start
  flex-wrap: wrap
  margin: 0 $col-gap
  margin-bottom: 3em
  @media screen and (min-width: 1025px)
    grid-template-columns: repeat(4, 1fr)

.ToggleCuratedButton
  padding: 10px
  border: $basic-border
  appearance: none
  background: $primary-color
  cursor: pointer
  outline: none
  @include caption
  &:hover
    opacity: 0.5

.ToggleCuratedIcon
  width: 28px
  margin-right: 10px

.PageHeaderBar
  padding-top: 4em
  margin-left: $col-gap
  margin-right: $col-gap
  margin-bottom: 10px
  display: flex
  justify-content: space-between

.Filters
  display: grid
  grid-template-columns: repeat(6, 1fr)
  gap: 10px
  margin-left: 20px
  margin-right: 20px
