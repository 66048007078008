@import 'shared/sass/index.sass'

.FilterWrapper
  border: $basic-border
  cursor: pointer
  transition: opacity 0.2s ease
  &:hover
    opacity: 0.6

.Filter
  height: 100%
  display: flex
  align-items: center
  color: $text-color-lighter
  border: solid 6px transparent
  padding: 8px 6px
  transition: color 0.2s ease, background-color 0.2s ease
  @include caption

.FilterActive
  background: $secondary-color
  color: white

.FilterIcon
  max-width: 45px
  max-height: 45px
  margin-right: 15px
  width: auto
  height: auto
