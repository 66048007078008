@import 'shared/sass/index.sass'

.Toolbar
  height: 60px
  width: 100vw
  top: 0
  left: 0
  background-color: $primary-color
  position: fixed
  z-index: 998
  display: flex
  flex-direction: row
  justify-content: flex-start
  border-bottom: $basic-border
  color: $text-color
  font-size: 0.8rem
  .CurrentNavigation
    display: flex
    align-items: center
    border-right: $basic-border
    flex: 4.5
    @include body-1
    @media screen and (max-width: 767px)
      display: none
    .CurrentNavigationPage
      display: flex
      align-items: center
      justify-content: center
      height: 100%
      padding: 0 10px
      margin: 0 10px
      transition: background-color 0.2s ease
      &:hover
        background-color: $secondary-color
        user-select: none
        cursor: pointer
  .CurrentProto
    // flex: 1.5
    width: 136px
    border-right: $basic-border
    display: flex
    align-items: center
    position: relative
    border: 6px solid transparent
    outline-right: $basic-border
    @include gradient-border
    user-select: none
    cursor: pointer
    .CurrentNavigationArrow
      position: absolute
      height: 30px
      top: 0.15em
      right: 0.15em
      @media screen and (max-width: 768px)
        height: 3em
    .CurrentNavigationText
      @include body-1
      width: 70%
      margin-left: 10px
      @media screen and (max-width: 768px)
        display: none
  .Connections
    // flex: 1.5
    width: 114px
    padding: 10px
    border-right: $basic-border
    display: flex
    align-items: center
    justify-content: flex-start
    border: 6px solid transparent
    outline: $basic-border
    position: relative
    max-width: 12em
    @media screen and (min-width: 1025px)
      width: 136px
    &:hover
      user-select: none
      cursor: pointer
    .ConnectionsArrow
      position: absolute
      height: 30px
      top: 0.15em
      right: 0.15em
      @media screen and (max-width: 768px)
        height: 3em
    .ConnectionsIcon
      height: 2em
      width: 2em
      max-height: 30px
      max-width: 30px
      margin-right: 0.5em
      @media screen and (max-width: 768px)
        display: none
    .Number
      @include subtitle-1

  .ConnectionsActive
    @include gradient-border

  .Hour
    // flex: 1
    border-right: $basic-border
    display: flex
    align-items: center
    justify-content: center
    padding: 0 20px
    @include subtitle-1
  .Volume
    width: 25%
    max-width: calc(400px - 2px)
  nav
    height: 100%
    width: 100%
    display: inline-block
    float: right
    padding-right: 10px
