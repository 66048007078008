@import 'shared/sass/index.sass'

.Wrapper
  border: 6px solid transparent
  &:hover
    border: 6px solid $secondary-color
    padding: 0
  &.Active
    border: 6px solid $secondary-color
    padding: 0
  &.Playing
    @include gradient-border
    padding: 0

.PlayablePreview
  color: $text-color
  position: relative

  &::after
    position: absolute
    height: 100%
    width: 100%
    border: $basic-border
    content: ''
    top: 0
  @media screen and (max-width: 768px)
    font-size: 1.2rem
  @media screen and (max-width: 512px)
    font-size: 1.5rem
  &:hover
    cursor: pointer
    user-select: none
    opacity: 0.7

.IconEnterBlock
  position: absolute
  right: 0
  top: 0
  border: 1px solid white
  border-radius: 2px
  background-color: $primary-color
  display: flex
  align-items: center
  justify-content: center
  height: 45px
  width: 45px
  z-index: 2
  img
    height: 80%

.ImageContainer
  height: 112px
  display: flex
  align-items: center
  justify-content: center
  background-color: black
  position: relative
  overflow: hidden
  background-position: center center
  background-size: cover

.StatusNotCurated
  position: absolute
  padding: 5px 12px
  font-size: 0.7em
  margin: 5px
  bottom: 0
  right: 0
  background-color: $status-notcurated-color
  text-transform: uppercase
  color: black
  letter-spacing: 0.15em

.Image
  height: 100%
  background-color: $secondary-color
  transform: scale(1.3)

.DescriptionContainer
  height: 42px
  display: flex
  align-items: center
  padding: 10px
  text-transform: uppercase

.Logo
  margin-right: 1em
  height: 100%

.TitleM
  @include subtitle-2
  margin-top: -0.3em
  padding-top: 5px

.TitleL
  @include subtitle-1
  font-size: 14px
