@import 'shared/sass/index.sass'

.Login
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  background-color: $primary-color
  color: $text-color

.Icon
  margin-right: 15px

.Button
  display: inline-block
  cursor: pointer
  padding: 15px 25px
  border: $basic-border

.Description
  @include subtitle-1
  line-height: 1.4
  text-align: center
  max-width: 30em
  margin-bottom: 20px

.Content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center