@font-face {
  font-family: "Roboto";
  src: url("./shared/fonts/Roboto-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "Roboto";
  src: url("./shared/fonts/Roboto-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("./shared/fonts/Roboto-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  src: url("./shared/fonts/Roboto-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("./shared/fonts/Roboto-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Roboto";
  src: url("./shared/fonts/Roboto-Black.ttf");
  font-weight: 900;
}

html {
  font-size: calc((1.5vw + 1.5vh) / 1.8);
}

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem !important;
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* ant-d */
.ant-collapse {
  background: #222222;
  border-radius: 0;
  border-color: black;
  font-size: 16px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #646464;
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 11px;
}

.ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header {
  color: white;
}

.ant-collapse-content {
  background: #222222;
  color: white;
  border-color: black;
}

.ant-collapse > .ant-collapse-item {
  border-color: black;
}