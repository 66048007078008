@import 'shared/sass/index.sass'

.SoftwareContainer
  padding: $col-gap

.ContainerTitle
  @include caption
  text-transform: uppercase
  margin-bottom: 10px
  color: $text-color-lighter

.Software
  border: $basic-border
  display: flex
  padding: 1em

.SoftwareLogo
  padding-right: 1.5em
  img
    height: 100%

.SoftwareName
  font-weight: 300
  letter-spacing: 0.10em
  margin-top: 0.15em
