@import 'shared/sass/index.sass'

.WarningNoticeContainer
  background-color: rgba(0,0,0,0.5)
  backdrop-filter: blur(1px)
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  height: 100%
  width: 100%
  z-index: 999
  .WarningNotice
    height: 399px
    width: 418px
    background-color: #ff6565
    padding: 0 20px
    z-index: 1000
    color: white
    padding: 20px
    position: relative
    .WarningNoticeHeader
      @include headline-1
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      margin-bottom: 30px
      margin-top: 10px
    .WarningNoticeMessage
      display: flex
      flex-direction: column
      padding: 0 20px
      @include body-2
      text-align: center
    .WarningNoticeAction
      position: absolute
      bottom: 40px
      width: calc(100% - 4vw)
      display: flex
      align-items: center
      justify-content: center
      .WarningNoticeButton
        @include subtitle-2
        border: 1px solid white
        width: 60%
        text-align: center
        padding: 1vw
        &:hover
          background-color: #ff9595
          cursor: pointer
      .Loading
        width: 100%
