@import 'shared/sass/index.sass'

.DemoContainer
  width: 100%
  position: absolute
  left: 0
  display: flex
  flex-direction: row
  .LeftContent
    width: 75%
    height: calc(100vh - 60px)
    overflow-y: scroll
    display: flex
    flex-direction: column
    @media screen and (min-width: 1025px)
      width: 80%
    .ContentHeader
      height: calc(180px - 1px)
      min-height: 180px
      border-bottom: $basic-border
      position: relative
      display: flex
      flex-direction: column
      justify-content: space-between
      padding: 20px
      .StopButton
        position: absolute
        display: flex
        align-items: center
        justify-content: center
        top: 20px
        right: 20px
        height: 58px
        width: 135px
        border-radius: 4em
        font-size: 2em
        &:hover
          cursor: pointer
          user-select: none
          opacity: 0.7
        img
          height: 100%
      .PlayButton
        position: absolute
        display: flex
        align-items: center
        justify-content: center
        background-color: $secondary-color
        top: 20px
        right: 20px
        height: 58px
        width: 135px
        border-radius: 4em
        font-size: 2em
        &:hover
          cursor: pointer
          user-select: none
          opacity: 0.7
        img
          height: 60%
      .Title
        @include headline-1
        max-width: calc(100% - 6em)
        white-space: nowrap
        overflow: hidden
      .CreatedContainer
        display: flex
        flex-direction: row
        padding-top: $col-gap
        @include body-1
        line-height: 1.8em
        .CreatedImage
          background-color: $secondary-color
          height: 3em
          width: 3em
          margin-right: 1em
          border-radius: 3em
        .CreatedInfo
          display: flex
          flex-direction: column
          .CreatedInfoText
          .CreatedInfoDate
    .ContentInfo
      display: flex
      background-color: $primary-color
      margin: $col-gap
      @media screen and (max-width: 512px)
        flex-direction: column
      .VisitInfos
        width: 50%
        @media screen and (max-width: 512px)
          width: 100%
          margin: 0.5em 0
        .DateAndLanguage
          margin-right: $col-gap
          display: flex
          flex-direction: column
          @include body-1
          text-transform: uppercase
          div
            margin-bottom: 1em
        .VisitGoals
          margin-right: $col-gap
          @include body-2
          .VisitGoalsTitle
            @include subtitle-2
            color: $text-color-lighter
            margin-bottom: 0.5em
      .DemoInfos
        width: 50%
        @media screen and (max-width: 512px)
          width: 100%
          margin: 0.5em 0
        .DemoPresentatorContainer
          margin: 0 $col-gap
          @include body-1
          .DemoPresentatorTitle
            @include subtitle-2
            color: $text-color-lighter
            margin-bottom: 0.5em
          .DemoPresentator
            display: flex
            align-items: center
            .DemoPresentatorPicture
              background-color: $secondary-color
              border-radius: 100%
              width: 3em
              height: 3em
              margin-right: 1em
            .DemoPresentatorName
        .DemoBriefContainer
          margin: 1em $col-gap
          @include body-2
          .DemoBriefTitle
            @include subtitle-2
            color: $text-color-lighter
            letter-spacing: 0.15em
            margin-bottom: 0.5em

    .VisitorsTitle
      margin: 0.5em 1em
    .Visitors
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-column-gap: $col-gap
      grid-row-gap: $col-gap
      margin: $col-gap
      @media screen and (min-width: 1068px)
        grid-template-columns: repeat(3, 1fr)
      .AddVisitorContainer
        width: 100%
        height: 240px
        margin: 0.7em
        display: flex
        align-items: center
        justify-content: center
        .ButtonAddVisitor
          width: 2em
          height: 2em
          border-radius: 100%
          color: $text-color
          display: flex
          align-items: center
          justify-content: center
          font-size: 3em
          &:hover
            cursor: pointer
            user-select: none
            opacity: 0.7
          div
            margin-top: -0.2em
      .Visitor
        height: 240px
        width: 100%
        display: flex
        padding: 0.1em
        position: relative
        flex-direction: column
        align-items: center
        justify-content: flex-start
        border: 0.15em solid $status-connected-color
        text-align: center
        @media screen and (min-width: 1025px)
          padding: 0 $col-gap
        &.StatusConnected
          border-color:  $status-connected-color
        &.StatusInvited
          border-color: $status-invited-color
        &.StatusCheckedIn
          border-color:  $status-checked-in-color
        .StatusContainer
          position: absolute
          top: 10px
          left: 10px
          .Status
            display: flex
            align-items: center
            @include body-1
            line-height: 1
            .StatusColorCircle
              background-color: #fff
              width: 18px
              height: 18px
              margin-right: 0.5em
              border-radius: 100%
              &.StatusConnected
                background-color:  $status-connected-color
              &.StatusInvited
                background-color: $status-invited-color
              &.StatusCheckedIn
                background-color:  $status-checked-in-color
        .Picture
          min-height: 80px
          min-width: 80px
          margin-top: 30px
          margin-bottom: 0.45em
          border-radius: 100%
          text-align: center
          background-color: $primary-color-lighter
          display: flex
          align-items: center
          justify-content: center
          @include subtitle-1
          font-size: 30px
          div
            margin-top: 0.10em
            user-select: none
        .Name
          @include subtitle-1
          margin-bottom: 5px
        .Company
          @include subtitle-2
          // position: absolute
          margin-bottom: 5px
          padding: 0 1em
          bottom: 0.5em
        .Occupation
          @include caption
          line-height: 15px
          margin-bottom: 10px
          padding: 0 1em
.RightContent
  width: 25%
  height: calc(100vh - 60px)
  overflow-y: scroll
  border-left: $basic-border
  @media screen and (min-width: 1025px)
    width: 20%

.PictureTakeaway
  width: 100%
  height: 100%
  background-size: cover
  background-position: center
  border-radius: 9999px