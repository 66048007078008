@import 'shared/sass/index.sass'

.NavigationItems
  margin: 0
  padding: 0
  display: flex
  flex-flow: column
  align-items: center
  justify-content: flex-start
  height: calc(100% - 70px)
  list-style: none
  font-size: 14px
  .NavigationItemInputsContainer
    height: 300px
    width: 100%
    display: flex
    align-items: center
    .NavigationItemInputs
      display: inline-block
      width: 100%
      text-align: center
      height: 72px
      display: flex
      align-items: center
      justify-content: space-evenly
      flex-direction: column
      padding: 10px
      @include caption
      color: $primary-color-lighter
      img
        transform: scale(1.1)
        height: 50%
        margin-bottom: 5px
      &:hover
        background-color: $primary-color-lighter
        color: white
        cursor: pointer
        user-select: none
  .NavigationItem
    width: 100%
    text-align: center
    height: 72px
    border-bottom: $basic-border
    display: flex
    align-items: center
    justify-content: space-evenly
    flex-direction: column
    padding: 10px
    @include caption
    color: $primary-color-lighter
    img
      transform: scale(1.1)
      height: 50%
      margin-bottom: 5px
      backface-visibility: hidden
    &:hover
      background-color: $primary-color-lighter
      cursor: pointer
      user-select: none
      color: white

  .NavigationItemLast
    border-top: $basic-border
    border-bottom: none
    align-self: flex-end